<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      checkout: {},
      checkoutId: null,
      limit: 20,
      page: 1,
      searchModel: "",
      searchMode: false,
      tot_pages: 0,
      attendancePage: 1,
      attends: [],
      HrAttendance: [],
      HrEmployees: [],
    };
  },
  methods: {
    getHrEmployees() {
      this.http.get("emps").then((res) => {
        this.HrEmployees = res.data;
      });
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    checkIn() {
      var data = {
        title: "hr_attendance.check_in",
        inputs: [
          {
            model: "emp_id",
            type: "select",
            options: (() => {
              return this.HrEmployees.map((data) => {
                return {
                  value: data?.id,
                  label: data?.name,
                };
              });
            })(),
            label: "emps.name",
          },
          // { model: "date", type: "date", label: "date" },
          { model: "check_in", type: "time", label: "hr_attendance.time" },
        ],
        buttons: [
          {
            text: "hr_attendance.check_in",
            closer: true,
            color: "primary",
            handler: (obj) => {
              let date = new Date();
              this.formatDate(date);
              obj.check_in = `${this.formatDate(date)} ${obj.check_in}:00`;
              obj.date = this.formatDate(date);
              console.log(obj);
              this.http.post("attendance", obj).then(() => {
                this.get(this.attendancePage);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    bildCheckout(app) {
      this.checkoutId = app.id;
    },
    empcheckOut() {
      let check_out = `${this.checkout.date} ${this.checkout.time}:00`;
      console.log({ checkout: this.checkout });
      console.log({ checkout2: check_out });
      this.http.put("attendance", this.checkoutId, { check_out }).then(() => {
        this.get(this.attendancePage);
      });
    },
    editHrAttendance(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "check_in",
            type: "time",
            label: "check_in",
            value: app.check_in,
          },
          {
            model: "check_out",
            type: "time",
            label: "check_out",
            value: app.check_out,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.hr_employee_id = app?.hr_employee?.id;
              this.http.put("hr-attendance", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    deleteHrAttendance(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("attendance", app.id).then(() => {
            this.get(this.attendancePage);
          });
        }
      });
    },
    searchAttendance() {
      this.HrAttendanceSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("attendance/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.HrAttendance = res.data;
        });
    },
    cancelAttendanceSearchMode() {
      this.searchModel = "";
      this.searchMode = false;
      this.get(this.page);
    },
    formatedTimestamp(checkTime) {
      const d = new Date(checkTime);
      const date = d.toISOString().split("T")[0];
      const time = d.toTimeString().split(" ")[0];
      return `${date} ${time}`;
    },
    get(page) {
      this.http
        .post("emps/get-attends", {
          limit: this.limit,
          page: page,
          emp_id: "*",
        })
        .then((res) => {
          console.log(res);
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.HrAttendance = res.data;
          console.log(this.HrAttendance);
        });
    },
  },
  created() {
    this.get(1);
    this.getHrEmployees();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('menu.menuitems.hr.subItems.emps-attendes')"
    />
    <!-- <table class="table m-0" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      
      <thead>
        <tr
          class="text-center text-light"
          style="background-color: #2a3042 !important"
        >
          <th>id</th>
          <th>Employee</th>
          <th>Check In</th>
          <th>Check Out</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="text-center"
          v-for="(emp_attend, index) in attends"
          :key="emp_attend"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ emp_attend?.emp?.name }}</td>
          <td>{{ emp_attend.check_in }}</td>
          <td>{{ emp_attend.cheak_out }}</td>
          <td>
            <router-link :to="'/emps_attends_detials/' + emp_attend.emp_id">
              <a
                class="btn btn-primary me-2"
                href="javascript: void(0);"
                role="button"
                >{{ $t("popups.details") }}</a
              >
            </router-link>
          </td>
        </tr>
      </tbody>
    </table> -->

    <!-- from kassal farm -->

    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="search-box chat-search-box w-50">
                <div class="position-relative">
                  <input
                    @keyup="searchAttendance()"
                    type="text"
                    class="form-control fa-lg text-light"
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    :placeholder="$t('popups.search')"
                    v-model="searchModel"
                    style="background-color: #2a3042 !important"
                  />
                  <i class="bx bx-search-alt search-icon text-light"></i>
                </div>
              </div>
            </div>
            <div class="col-6" style="display: flex; justify-content: flex-end">
              <button
                class="btn-close text-light"
                v-if="searchMode"
                @click="cancelAttendanceSearchMode()"
              ></button>
              <div class="d-flex">
                <!-- <button
                type="button"
                @click="checkOut()"
                class="btn btn-light float-end mb-4"
              >
                <span
                  class="bx bxs-log-out float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button> -->
                <button
                  type="button"
                  @click="checkIn()"
                  class="btn btn-light float-end mb-4"
                >
                  <span
                    class="bx bxs-log-in float-end fa-2x text-success"
                  ></span>
                </button>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-striped table-hover mb-0"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("hr_attendance.code") }}</th>
                  <th scope="col">{{ $t("hr_attendance.hr_employee_id") }}</th>
                  <th scope="col">{{ $t("hr_attendance.check_in_day") }}</th>
                  <th scope="col">{{ $t("hr_attendance.check_in_time") }}</th>
                  <th scope="col">{{ $t("hr_attendance.check_out_day") }}</th>
                  <th scope="col">{{ $t("hr_attendance.check_out_time") }}</th>
                  <!-- <th scope="col">{{ $t("hr_attendance.created") }}</th>
          <th scope="col">{{ $t("hr_attendance.updated") }}</th> -->
                  <th scope="col">{{ $t("hr_attendance.operations") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, index) in HrAttendance" :key="app" class="">
                  <td>{{ index + 1 }}</td>
                  <td>{{ app?.emp?.code }}</td>
                  <td>{{ app?.emp?.name }}</td>
                  <td>
                    {{ app?.check_in ? app?.check_in.split("T")[0] : "-" }}
                  </td>
                  <td>
                    {{
                      app?.check_in
                        ? app?.check_in.split("T")[1]?.split(".")[0]
                        : "-"
                    }}
                  </td>
                  <td>
                    {{ app?.check_out ? app?.check_out.split("T")[0] : "-" }}
                  </td>
                  <td>
                    {{
                      app?.check_out
                        ? app?.check_out.split("T")[1]?.split(".")[0]
                        : "-"
                    }}
                  </td>
                  <!-- <td>{{ app.created.split("T")[0] }}</td>
          <td>{{ app.updated.split("T")[0] }}</td> -->

                  <td class="d-flex">
                    <button
                      style="margin-inline-start: 8px"
                      class="btn btn-light"
                      data-bs-toggle="modal"
                      data-bs-target="#checkoutModal"
                      @click="bildCheckout(app)"
                      :disabled="app?.check_out"
                    >
                      <i class="bx bxs-log-out text-primary fa-2x"></i>
                    </button>

                    <button
                      class="btn btn-danger"
                      href="javascript: void(0);"
                      role="button"
                      @click="deleteHrAttendance(app)"
                    >
                      {{ $t("popups.delete") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- end from kassal farm -->

    <div
      class="modal fade"
      id="checkoutModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="empcheckOut()">
            <div class="modal-header">
              <div class="d-flex justify-content-around">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ $t("hr_attendance.check_out") }}
                </h5>
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <label for="">{{ $t("hr_attendance.date") }}</label>
                <input
                  type="date"
                  v-model="checkout.date"
                  class="form-control mb-2"
                />
              </div>
              <div class="row">
                <label for="">{{ $t("hr_attendance.time") }}</label>
                <input
                  type="time"
                  v-model="checkout.time"
                  class="form-control mb-2"
                />
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button
                type="submit"
                data-bs-dismiss="modal"
                class="btn btn-primary"
              >
                {{ $t("hr_attendance.check_out") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->
  </Layout>
</template>
<style scoped></style>
